.vat-program-module {
  &.-active {
    border: 1px solid var(--gray-400);
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.14);
  }
}

.program-module-circle {
  @apply absolute bg-white border rounded-full h-8 w-8;
  top: 20px;
  left: 12px;
  z-index: 2;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.14);
}

.program-module-position {
  @apply absolute text-lg font-bold w-8;
  left: 12px;
  text-align: center;
  top: 24.5px;
  z-index: 3;
}

.program-module-milestone {
  @apply absolute;
  left: 18.5px;
  top: 26px;
  z-index: 3;
}

.program-module-calendar {
  @apply absolute;
  left: 8.5px;
  top: 20px;
  z-index: 3;
}
