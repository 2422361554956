.trix-content {
  > * {
    @apply mb-3;
  }
  > *:last-child {
    @apply mb-0;
  }

  & strong {
    @apply font-bold;
  }

  & a {
    @apply text-sky-600 cursor-pointer;
  }

  & b {
    @apply font-bold;
  }

  & h1 {
    @apply leading-7 tracking-normal font-medium text-2xl mb-7 mt-2;
  }

  & h2 {
    @apply leading-7 tracking-normal font-medium text-xl mb-5 mt-2;
  }

  & h3 {
    @apply leading-5 tracking-normal font-medium text-lg mb-2 mt-2;
  }

  & p {
    @apply tracking-normal font-normal text-base mb-2;
  }

  & blockquote {
    padding: 15px 20px 15px 35px !important;
    position: relative;
    text-align: justify;
    border-left: none !important;

    &::before {
      line-height: 1.5rem;
      content: "\201C";
      font-family: Georgia, serif;
      font-size: 40px;
      font-weight: bold;
      position: absolute;
      left: 10px;
    }
  }

  ol,
  ul {
    padding-left: 1.5rem;
  }

  ol > li,
  ul > li {
    list-style-type: none;
  }

  ul > li::before {
    content: '\2022';
  }

  li::before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2rem;
  }

  li:not(.ql-direction-rtl)::before {
    margin-left: -1.5rem;
    margin-right: 0.3rem;
    text-align: right;
  }

  li.ql-direction-rtl::before {
    margin-left: 0.3rem;
    margin-right: -1.5rem;
  }

  ol li:not(.ql-direction-rtl),
  ul li:not(.ql-direction-rtl) {
    padding-left: 1.5rem;
  }

  ol li.ql-direction-rtl,
  ul li.ql-direction-rtl {
    padding-right: 1.5rem;
  }

  ol li {
    counter-reset: list-1 list-2 list-3;
    counter-increment: list-0;

    &::before {
      content: counter(list-0, decimal) '. ';
    }

    ol li {
      counter-increment: list-1;
      counter-reset: list-2 list-3;

      &::before {
        content: counter(list-1, lower-alpha) '. ';
      }

      ol li {
        counter-increment: list-2;
        counter-reset: list-3;

        &::before {
          content: counter(list-2, lower-roman) '. ';
        }

        ol li {
          counter-increment: list-3;
          counter-reset: list-4;

          &::before {
            content: counter(list-3, decimal) '. ';
          }
        }
      }
    }
  }

  ol li.ql-indent-1 {
    counter-increment: list-1;
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;

    &::before {
      content: counter(list-1, lower-alpha) '. ';
    }
  }

  ol li.ql-indent-2 {
    counter-increment: list-2;
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;

    &::before {
      content: counter(list-2, lower-roman) '. ';
    }
  }

  ol li.ql-indent-3 {
    counter-increment: list-3;
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;

    &::before {
      content: counter(list-3, decimal) '. ';
    }
  }

  ol li.ql-indent-4 {
    counter-increment: list-4;
    counter-reset: list-5 list-6 list-7 list-8 list-9;

    &::before {
      content: counter(list-4, lower-alpha) '. ';
    }
  }

  ol li.ql-indent-5 {
    counter-increment: list-5;
    counter-reset: list-6 list-7 list-8 list-9;

    &::before {
     content: counter(list-5, lower-roman) '. ';
    }
  }

  ol li.ql-indent-6 {
    counter-increment: list-6;
    counter-reset: list-7 list-8 list-9;

    &::before {
      content: counter(list-6, decimal) '. ';
    }
  }

  ol li.ql-indent-7 {
    counter-increment: list-7;
    counter-reset: list-8 list-9;

    &:before {
      content: counter(list-7, lower-alpha) '. ';
    }
  }

  ol li.ql-indent-8 {
    counter-increment: list-8;
    counter-reset: list-9;

    &::before{
      content: counter(list-8, lower-roman) '. ';
    }
  }

  ol li.ql-indent-9 {
    counter-increment: list-9;

    &::before {
      content: counter(list-9, decimal) '. ';
    }
  }

  .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3rem;
  }

  .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 5rem;
  }

  .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 7rem;
  }

  .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 9rem;
  }

  .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 11rem;
  }

  .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 13rem;
  }

  .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 15rem;
  }

  .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 17rem;
  }

  .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 19rem;
  }

  & hr {
    @apply border border-gray-400;
  }

  & .ql-align-center {
    @apply text-center;
  }

  & .ql-align-right {
    @apply text-right;
  }

  & img {
    image-orientation: from-image;
    display: inline-block;
    margin: 0 auto !important;
    max-width: 100% !important;
  }

  & .ql-video {
    height: 0;

    max-width: 100%;
    overflow: hidden;
    padding-bottom: 56.25%; /*16:9*/
    padding-top: 0px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    embed,
    object,
    iframe {
      height: 100% !important;
      left: 0;

      position: absolute;
      top: 0;
      width: 100% !important;
    }
  }
}
