@use "sass:meta";
@tailwind base;
@tailwind components;
@tailwind utilities;

@include meta.load-css("trix_content_pages.scss");
@include meta.load-css("trix_advanced_editor.scss");
@include meta.load-css("trix_content.scss");
@include meta.load-css("spinner.scss");
@include meta.load-css("space_between.scss");
@include meta.load-css("actiontext.scss");
@include meta.load-css("tribute.scss");
@include meta.load-css("tabs.scss");
@include meta.load-css("form_textarea.scss");
@include meta.load-css("drag_and_drop.scss");
@include meta.load-css("vat.scss");
@include meta.load-css("program_module.scss");
@include meta.load-css("card.scss");
@include meta.load-css("layout.scss");
@include meta.load-css("icon.scss");
@include meta.load-css("race_component.scss");
@include meta.load-css("header.scss");
@include meta.load-css("datalist.scss");
@include meta.load-css("sticky_section.scss");
@include meta.load-css("programs_header.scss");
@include meta.load-css("programs_state_filter.scss");
@include meta.load-css("program_cards.scss");
@include meta.load-css("buttons.scss");
@include meta.load-css("filters.scss");
@include meta.load-css("selection_controls.scss");
@include meta.load-css("dropdown_menus.scss");
@include meta.load-css("nudl.scss");
@include meta.load-css("accordion.scss");
@include meta.load-css("background_images.scss");
@include meta.load-css("notification.scss");
@include meta.load-css("messages.scss");
@include meta.load-css("cta.scss");
@include meta.load-css("utilities.scss");
@include meta.load-css("segmented_controls.scss");
@include meta.load-css("inputs.scss");
@include meta.load-css("table.scss");
@include meta.load-css("number_picker.scss");
@include meta.load-css("date_picker.scss");
@include meta.load-css("admin_dropdowns.scss");
