.datalist-field {
  .datalist-search-list {
    @apply hidden;
  }

  &.active {
    @apply border-gray-200 absolute inset-x-0 shadow-lg z-10;

    .datalist-icon {
      @apply rotate-180;
    }

    .datalist-search-list {
      @apply flex;
    }
  }
}
