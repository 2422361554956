.card-hover:hover {
  .highlight-card-icon {
    .primary {
      stroke: theme("colors.gray.900");
    }
  }

  .highlight-card-link {
    @apply text-primary-500 underline;
  }
}
