.admin-trix-template {
  .trix-content {
    & iframe {
      @apply w-full;
    }
    & align-left {
      @apply text-left;
    }
    & align-right {
      @apply text-right;
    }
    & align-center {
      @apply text-center;
    }
    align-right, align-left, align-center {
      width: 100%;
      display: block;
      white-space: normal;
    }

    [style*="background-color: highlight;"] {
      background-color: transparent !important;
    }

    & hr {
      @apply border border-gray-400;
    }
    & h1 {
      @apply leading-7 tracking-normal font-medium text-2xl mb-7 mt-2;
    }
    & h2 {
      @apply leading-7 tracking-normal font-medium text-xl mb-5 mt-2;
    }
    & h3 {
      @apply leading-5 tracking-normal font-medium text-lg mb-2 mt-2;
    }
    h1, h2, h3 {
      font-weight: 500;
      margin-top: 10px;

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    h1, h2, h3, h4, h5, h6 {
      line-height: 1.2;
      margin: 0;
    }
    h1 { font-size: 36px; }
    h2 { font-size: 26px; }
    h3 { font-size: 18px; }
    h4 { font-size: 18px; }
    h5 { font-size: 14px; }
    h6 { font-size: 12px; }

    .attachment { width: 100%; }
    .attachment--content.attachment--horizontal-rule,
    .attachment--content[data-trix-content-type~='vnd.rubyonrails.horizontal-rule.html'] {
      padding: 1.5em 0 0.5em !important;
      margin: 0.5rem 0;
    }
    .attachment--content.attachment--horizontal-rule hr,
    .attachment--content[data-trix-content-type~='vnd.rubyonrails.horizontal-rule.html'] hr {
      margin: 0;
      width: 100%;
      border-color: var(--gray-600) !important;
    }
    .attachment--file {
      width: fit-content;
    }

    div:not(.attachment-gallery) > .attachment--preview {
      width: fit-content;
      height: 100%;
      text-align: inherit;
      vertical-align: bottom;
      display: inline-flex;
      white-space: nowrap;
      position: relative;
      padding: 0;
    }
  }

  .trix-header-select {
    border-radius: 0.25rem;
    display: inline-block;
    position: relative;
    padding: 2px 10px 2px 10px;
    font-size: inherit;
    border: 1px solid var(--gray-400);
    font-weight: 500;
    min-width: 120px;
  }

  .trix-picker {
    color: var(--gray-900);
    display: flex;
    height: 24px;
    position: relative;
    vertical-align: middle;
  }

  .trix-picker-options {
    border: 1px solid transparent;
    box-shadow: var(--gray-900) 0 2px 8px;
    display: none;
    min-width: 100%;
    padding: 4px 8px;
    position: absolute;
    white-space: nowrap;

    .trix-picker-item {
      cursor: pointer;
      display: block;
      padding-bottom: 5px;
      padding-top: 5px;
    }
  }

  .trix-picker.trix-header {
    width: 98px;

    &.trix-expanded {
      .trix-picker-label {
        z-index: 2;
      }
      .trix-picker-options {
        display: block;
        margin-top: -1px;
        top: 100%;
        z-index: 1;
      }
    }

    .trix-picker-label[data-trix-attribute="heading1"]::before { content: 'Heading 1' }
    .trix-picker-label[data-trix-attribute="heading2"]::before { content: 'Heading 2' }
    .trix-picker-label[data-trix-attribute="heading3"]::before { content: 'Heading 3' }
    .trix-picker-item[data-trix-attribute="heading1"]::before {
      content: 'Heading 1';
      font-size: 2em;
    }
    .trix-picker-item[data-trix-attribute="heading2"]::before {
      content: 'Heading 2';
      font-size: 1.5em;
    }
    .trix-picker-item[data-trix-attribute="heading3"]::before {
      content: 'Heading 3';
      font-size: 1.17em;
    }
    .trix-picker-item::before, .trix-picker-item::before {
      content: 'Normal'
    }

    .trix-picker-label {
      display: grid;
      position: relative;
      border: 1px solid transparent;
      line-height: 28px;
      overflow: visible;

      &:before {
        display: inline-block;
        line-height: 22px;
      }
    }
  }

  .trix-picker.trix-expanded {
    .trix-picker-label {
      border-color: var(--gray-400);
      color: var(--gray-400);
      z-index: 2;
    }

    .trix-picker-options {
      border-color: var(--gray-400);
      display: block;
      margin-top: -1px;
      top: 100%;
      z-index: 1;
    }
  }

  .trix-icon-picker {
    .trix-picker-label {
      display: grid;
      cursor: pointer;

      &::before {
        display: inline-block;
        line-height: 22px;
      }
    }

    .trix-picker-item {
      padding: 2px 4px;
      width: 20px;

      .trix-selected {
        background-color: var(--blue-500);
        border-radius: 4px;
      }

      &:hover {
        .trix-stroke {
          stroke: var(--blue-500);
        }
      }
    }

    .trix-picker-options {
      padding: 4px 0;
    }
  }

  .trix-stroke {
    fill: none;
    stroke: var(--gray-800);
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
  }

  .trix-stroke-miter {
    fill: none;
    stroke: inactiveColor;
    stroke-miterlimit: 10;
    stroke-width: 2;
  }
  .trix-empty { fill: none; }
  .trix-even { fill-rule: evenodd; }
  .trix-transparent { opacity: 0.4; }
  .trix-fill, .trix-stroke.trix-fill {
    fill: var(--gray-900)
  }
  .trix-thin, .trix-stroke.trix-thin {
    stroke-width: 1
  }
  .disabled .trix-stroke,
  button:hover.disabled .trix-stroke {
    stroke: var(--gray-400) !important
  }
  .trix-direction {
    svg:last-child {
      display: none;
    }
    &.trix-active {
      svg:last-child {
        display: inline;
      }
      svg:first-child {
        display: none;
      }
    }
  }

  .trix-align-center {
    text-align: center;
  }
  .trix-align-justify {
    text-align: justify;
  }
  .trix-align-right {
    text-align: end;
  }
  .trix-align-left {
    text-align: start;
  }
  .trix-direction-rtl {
    direction: rtl;
    text-align: inherit;
  }

  trix-editor {
    .copy-embed-message {
      top: -2em;
      left: -7.5em;
      position: relative;
      display: none;
      transition: opacity 200ms ease-in;
      border: 0 solid #F5F5F5;
      border-radius: 4px;
      font-size: 0.6rem;
      line-height: 1.145rem;
      padding: 0.1rem 0.28rem;
      letter-spacing: 0.014rem;
      font-weight: 400;
      color: #ffffff;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
      background-color: #444444;
      white-space: nowrap;
      z-index: 10;
    }

    .trix-button--icon-copy-url {
      height: auto;
      border-radius: 50%;
      border: 1px solid #CCCCCC !important;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
      background-color: #fff;
      text-indent: -9999px;
      cursor: pointer;
      outline: none;
      z-index: 1;

      &:hover {
        border-color: #333;
        cursor: pointer;

        &::before {
          opacity: 1;
        }
      }

      &::before {
        display: block;
        position: relative;
        opacity: 0.7;
        content: "";
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    .embed-container {
      position: unset;
      overflow: unset;
      padding: 0;

      .embed-link-copy {
        display: block;
        position: relative;
        text-align: center;

        .trix-button--icon-copy-url {
          top: -10px;
          position: relative;

          &::before {
            top: 1px;
            right: 0;
            bottom: 0;
            left: 0px;
            width: 24px;
            height: 24px;
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUgMTJINS4wMU0xMiAxMkgxMi4wMU0xOSAxMkgxOS4wMU02IDEyQzYgMTIuNTUyMyA1LjU1MjI4IDEzIDUgMTNDNC40NDc3MiAxMyA0IDEyLjU1MjMgNCAxMkM0IDExLjQ0NzcgNC40NDc3MiAxMSA1IDExQzUuNTUyMjggMTEgNiAxMS40NDc3IDYgMTJaTTEzIDEyQzEzIDEyLjU1MjMgMTIuNTUyMyAxMyAxMiAxM0MxMS40NDc3IDEzIDExIDEyLjU1MjMgMTEgMTJDMTEgMTEuNDQ3NyAxMS40NDc3IDExIDEyIDExQzEyLjU1MjMgMTEgMTMgMTEuNDQ3NyAxMyAxMlpNMjAgMTJDMjAgMTIuNTUyMyAxOS41NTIzIDEzIDE5IDEzQzE4LjQ0NzcgMTMgMTggMTIuNTUyMyAxOCAxMkMxOCAxMS40NDc3IDE4LjQ0NzcgMTEgMTkgMTFDMTkuNTUyMyAxMSAyMCAxMS40NDc3IDIwIDEyWiIgc3Ryb2tlPSIjMTExODI3IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 70%;
          }

          &.toolbar__icon {
            top: 2px;
            right: 0px;
            position: absolute;
          }
        }
      }
    }

    [data-trix-content-type~='application/octet-stream'], [data-trix-content-type~='undefined'] {
      .with-embeds {
        width: 100%;
        position: relative;
        text-align: center;
        display: block !important;

        .embed-link-copy {
          float: left !important;
          display: block;
          width: 100%;

          .trix-button--icon-copy-url {
            float: none !important;
            display: inline-block;
            top: 4px;

            &::before {
              width: 24px;
              height: 24px;
              top: 7px;
              right: 0px;
              bottom: 0;
              left: 6px;
              background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMy44NjUgNi4xNDFsMi42Mi0yLjYyIiBzdHJva2U9IiMwMDAiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48cGF0aCBkPSJNOCA0LjE0N2wuNjkyLS42OTRjLjU4OS0uNTg4LjU5MS0xLjU0NC4wMDgtMi4xMjhhMS40OTkgMS40OTkgMCAwMC0yLjEyOS4wMDdsLS42OTMuNjk0bS0xLjQyIDEuNDE5YTIuNTEzIDIuNTEzIDAgMDEuNzEzLTIuMTI3bC42OTMtLjY5M0EyLjQ5OSAyLjQ5OSAwIDExOS40IDQuMTYxbC0uMjE3LjIxNy0uNDc3LjQ3NmEyLjUwOCAyLjUwOCAwIDAxLTIuMTI1LjcxNmwtLjAwMy0uMDAyTDggNC4xNDcgNS44NzggMi4wMjVsLTEuNDIgMS40MnpNMi4zMjUgNS41MjhsLS42OTMuNjkzYTEuNDk5IDEuNDk5IDAgMTAyLjEyMSAyLjEyMWwuNjk0LS42OTNtMS40MTktMS40MmEyLjUxMyAyLjUxMyAwIDAxLS43MTIgMi4xMjdsLS42OTQuNjk0QTIuNDk5IDIuNDk5IDAgMTEuOTI1IDUuNTE0bC4yMTctLjIxNy40NzYtLjQ3NmEyLjUwOCAyLjUwOCAwIDAxMi4xMjYtLjcxNmwuMDAyLjAwMi0xLjQyIDEuNDIgMi4xMiAyLjEyMiAxLjQyLTEuNDJ6IiBmaWxsPSIjMDAwIi8+PC9nPjwvc3ZnPg==);
            }

            &.toolbar__icon {
              display: inline-block;
              position: relative;
            }
          }
        }

        .trix-button--remove {
          top: -30px;
          height: 24px !important;
          width: 24px !important;
          float: right !important;
          position: relative;
          border: 1px solid #CCCCCC !important;

          &::before {
            background-size: 80% !important;
          }
        }
      }
    }

    [data-trix-mutable].attachment[data-trix-content-type~="vnd.rubyonrails.horizontal-rule.html"] {
      box-shadow: 0 0 0 2px highlight;
    }

    ol {
      list-style-type: decimal;
      padding-left: 1rem;
    }

    ul {
      list-style-type: disc;
      padding-left: 1rem;
    }

    li {
      & h1, h2, h3 {
        display: inline-block;
      }
    }

    pre {
      display: inline-block;
      width: 100%;
      vertical-align: top;
      font-family: monospace;
      font-size: 1.5em;
      padding: 0.5em;
      white-space: pre;
      background-color: var(--gray-400);
      overflow-x: auto;
    }
    blockquote {
      padding: 15px 20px 15px 35px !important;
      position: relative;
      text-align: justify;
      border-left: none !important;
      border: 0 solid var(--gray-400);

      &::before {
        font-size: 25px !important;
        left: 12px;
      }
    }

    &:empty:not(:focus)::before {
      @apply text-gray-800;
    }

    &:empty::before {
      content: attr(placeholder);
      @apply text-gray-500;
    }
  }

  trix-toolbar {
    border: 1.5px solid var(--gray-600);
    border-bottom: 0 !important;
    border-radius: 0.375rem 0.375rem 0 0;

    .trix-button.trix-active {
      background-color: transparent !important;
    }

    .trix-dialog {
      padding: 5px;

      .trix-dialog-hidden__input {
        position: absolute;
        z-index: -1;
        opacity: 0;
      }
    }

    .trix-dialog--heading {
      max-width: 150px;
      left: 100px !important;
      border: 0 !important;
      height: 150px !important;
      border-radius: 2px !important;
      padding: 6px 0 0 10px;
      top: -5px;

      .trix-picker.trix-header {
        border: 0 !important;

        .trix-picker-item {
          padding: 2px;
          cursor: pointer;
        }
      }
    }

    .trix-dialog--link {
      width: 40%;
      margin: 0px;
      padding: 4px;
      top: 0 !important;
      left: 0 !important;
      border: 1px solid var(--gray-400) !important;
      border-radius: 2px !important;
      box-shadow: 0 0 5px var(--gray-400);
      background-color: var(--gray-100);
      color: var(--gray-900);
      white-space: nowrap;
      transform: translateY(10px);

      input[type=url] {
        height: 28px;
        margin: 0 2px 0 4px;
        padding: 3px 2px 3px 6px;
        border: 1px solid var(--gray-400);
      }

      .trix-button-group {
        margin: 0 4px 0 2px !important;

        .trix-button {
          padding: 0px;
          font-weight: initial;
          width: 40px !important;
        }
      }
    }

    .trix-dialog--embed {
      top: 0 !important;
      left: 0 !important;
      border: 1px solid var(--gray-400) !important;
      border-radius: 2px !important;
      box-shadow: 0 0 5px var(--gray-400);
      background-color: var(--gray-100);
      color: var(--gray-900);
      white-space: nowrap;
      transform: translateY(15px);
      width: 280px !important;
      padding: 5px 0px 5px 10px !important;

      input[type=url] {
        border: 1px solid var(--gray-400);
        height: 26px;
        margin: 0;
        padding: 3px 5px;
        width: 170px;
        margin: 0 5px;
      }

      input[type=button] {
        cursor: pointer;
        text-decoration: none;
        color: var(--blue-500);
        padding: 0px 0 0 5px;
        float: none;

        &:after {
          border-right: 0;
          content: 'Save';
          padding-right: 0;
          margin-left: 16px;
        }
      }
    }

    .trix-dialog--alignment {
      left: 360px !important;
      border: 0 !important;
      height: 65px;
      width: 30px;
      padding: 3px 5px !important;
      top: 0 !important;
      border-radius: 2px !important;

      .trix-dialog__alignment-fields {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
      }

      .trix-icon-picker {
        .trix-picker-label {
          display: grid;
          cursor: pointer;

          &::before {
            display: inline-block;
            line-height: 22px;
          }
        }

        .trix-picker-item {
          width: 20px;
          padding: 2px;

          .trix-selected {
            background-color: var(--blue-500);
            border-radius: 4px;
          }

          &:hover {
            .trix-stroke {
              stroke: var(--blue-500);
            }
          }
        }
      }
    }

    .trix-button-group:not(:first-child) {
      margin-left: 15px;
    }

    .trix-button:not(:first-child) {
      border-left: 0;
    }

    .trix-button--icon::before {
      background-size: 1.2rem;
    }

    .trix-button--icon-code,
    .trix-button--icon-strike {
      display: none;
    }

    .trix-button--icon-alignment {
      padding: 2px;

      &.open {
        border: 1px solid var(--gray-200);

        .trix-stroke {
          stroke: var(--gray-400);
        }
      }
    }

    .trix-button--icon-embed > svg,
    .trix-button--icon-attach > svg {
      .trix-fill { fill: var(--gray-800); }
      .trix-stroke { stroke: var(--gray-800); }
    }
    .trix-button--icon-embed:hover,
    .trix-button--icon-attach:hover {
      color: var(--blue-500) !important;
    }
    .trix-button--icon-embed:hover .trix-fill,
    .trix-button--icon-attach:hover .trix-fill {
      fill: var(--blue-500) !important;
    }
    .trix-button--icon-embed:hover .trix-stroke,
    .trix-button--icon-attach:hover .trix-stroke {
      stroke: var(--blue-500) !important;
      fill: none !important;
    }

    .trix-button--icon-bold::before,
    .trix-button--icon-italic::before,
    .trix-button--icon-attach::before,
    .trix-button--icon-link::before,
    .trix-button--icon-quote::before,
    .trix-button--icon-number-list::before,
    .trix-button--icon-bullet-list::before,
    .trix-button--icon-horizontal-rule::before,
    .trix-button--icon-redo::before,
    .trix-button--icon-undo::before {
      background-image: none !important;
    }

    .trix-button--icon-redo:disabled,
    .trix-button--icon-undo:disabled {
      .trix-stroke {
        stroke: var(--gray-400);
      }
    }
    .trix-active-stroke {
      stroke: var(--blue-500) !important;
    }
    .trix-selected,
    .trix-picker-item:hover,
    .trix-picker-label[data-trix-active] {
      color: var(--blue-500) !important;
    }

    .trix-button--icon-embed:hover,
    .trix-button--icon-embed.trix-active,
    button[data-trix-active]:not(:disabled),
    .trix-picker-label.trix-active:not(:disabled),
    .trix-button-group button.trix-active:not(:disabled),
    .trix-button-group button:hover:not(:disabled) {
      .trix-stroke {
        stroke: var(--blue-500);
      }
    }
    .disabled .trix-stroke,
    button:hover.disabled .trix-stroke {
      stroke: #ccc !important;
    }

    .trix-button-group--custom-tools {
      margin-left: 0 !important;
    }

    .trix-button-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow-x: auto;
      scrollbar-width: thin;
    }

    .trix-picker {
      color: var(--gray-900);
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      height: 24px;
      position: relative;
      vertical-align: middle;
    }

    .trix-button {
      background: none;
      border: none;
      cursor: pointer;
      display: inline-block;
      float: left;
      height: 36px;
      padding: 9px;
      width: 36px;

      &.trix-button--icon-horizontal-rule {
        font-size: 16px;

        &:hover {
          color: var(--blue-500);
        }
      }
    }
  }

  .attachment__toolbar {
    position: absolute;
    z-index: 1;
    top: -0.9em;
    left: 0;
    width: 100%;
    text-align: center;

    .attachment__metadata-container {
      display: none;
    }
  }

  &.slate {
    & trix-toolbar {
      @apply border-slate-400;
    }

    & trix-editor:empty::before {
      content: attr(placeholder);
      @apply text-slate-300;
    }
  }
  div:not(.attachment-gallery) {
    figure[data-trix-content-type^='image/'] .attachment__toolbar .trix-button-group--actions {
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
      margin-top: -19px;

      &.-thumbnail {
        flex-direction: column-reverse;
      }

      nav.__tabs {
        font-weight: 600;
        display: inline-flex;
        width: 100%;

        button.__tab {
          background: var(--slate-100);
          color: var(--slate-800);
          border: 1px solid transparent;

          &:hover,
          &.-active {
            background: white;
            border-color: var(--slate-100);
          }
        }

        &.-small {
          margin-left: -2px;
        }

        &.-thumbnail {
          width: 100%;
          margin-top: -7.25em;
          margin-right: auto;
          margin-left: auto;
          display: inline-grid;
        }

        [data-trix-action~='resizeImage'] {
          width: 100%;
          font-size: 10px;
          margin-right: 1px;
          padding-top: 6px;
          padding-bottom: 6px;

          &.trix-image--thumbnail {
            padding: 4px;
            font-size: 8px;
          }

          &.trix-image--small {
            font-size: 8px;
            padding: 6px 4px;
            margin-top: 3px
          }
        }
      }

      .trix-button--remove {
        border: 1px solid var(--slate-300);
        margin-top: 5px;
        margin-left: 8px;
        box-shadow: none;

        &.-small {
          width: 1.5em;
          height: 1.5em;
          align-self: center;
          margin-left: 3px;
          margin-top: 3px;
          float: none !important;
          top: 0px;
          font-size: 80%;
        }

        &.-thumbnail {
          width: 1.5em;
          height: 1.5em;
          align-self: center;
          margin-left: 0;
          margin-top: 3px;
          float: none !important;
          top: 0px;
          font-size: 65%;
        }


        &:hover {
          border-width: 1.5px;
          border-color: var(--red-600);

          &::before {
            opacity: 1;
          }
        }
      }
    }
  }

  div:not(.attachment-gallery) {
    figure:not(.attachment--file) {
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 0;

      & [data-trix-content-type^='image/'] {
        width: fit-content !important;
      }

      img {
        width: 100%;
        height: auto;
        image-orientation: from-image;
      }
    }

    > .attachment--preview {
      margin: 2em 0;
      height: 100%;
      padding: 0;
    }

    > .attachment--content {
      position: relative;
      display: inline-block;
      padding-bottom: 56.25%; /*16:9*/
      padding-top: 0;
      height: 0;
      width: 100%;

      &.attachment--jpg,
      &.attachment--png,
      &.attachment--heic {
        height: 100% !important;
      }

      &[data-trix-content-type~='application/octet-stream'],
      &[data-trix-content-type~='undefined'] {
        margin-top: 10px;
      }
    }
  }
}
