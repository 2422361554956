/* No longer supported in Tailwind 2.0.2 so we need to add this manually */
@layer utilities {
  .scrolling-touch {
    -webkit-overflow-scrolling: touch;
  }
}

.js-hidden {
  display: none !important;
}
