.logotype {
  background-image: url("~images/default/logo.svg");
}

.background-image {
  background-image: url("~images/default/image_branding.jpg");
}

.background-image-header {
  background-image: url("~images/default/branding_header.jpeg");
}
