.primary-button-icon,
.cta-button-icon {
  .primary {
    stroke: theme("colors.white");
  }

  &:disabled {
    .primary {
      stroke: theme("colors.gray.600");
    }
  }
}

.secondary-button-icon {
  .primary {
    stroke: theme("colors.primary.700");
  }

  &:disabled {
    .primary {
      stroke: theme("colors.primary.200");
    }
  }
}

.tertiary-button-icon {
  .primary {
    stroke: theme("colors.white");
  }

  &.brand {
    .primary {
      stroke: theme("colors.primary.700");
    }

    &:disabled {
      .primary {
        stroke: theme("colors.primary.200");
      }
    }
  }

  &.red {
    .primary {
      stroke: theme("colors.red.800");
    }

    &:disabled {
      .primary {
        stroke: theme("colors.red.200");
      }
    }
  }

  &.yellow {
    .primary {
      stroke: theme("colors.yellow.800");
    }

    &:disabled {
      .primary {
        stroke: theme("colors.yellow.200");
      }
    }
  }

  &.blue {
    .primary {
      stroke: theme("colors.sky.800");
    }

    &:disabled {
      .primary {
        stroke: theme("colors.sky.200");
      }
    }
  }

  &.green {
    .primary {
      stroke: theme("colors.green.800");
    }

    &:disabled {
      .primary {
        stroke: theme("colors.green.200");
      }
    }
  }
}

.primary-button-admin {
  &.tonal,
  &.outlined,
  &.text {
    &:hover:not(:disabled) {
      .primary {
        stroke: theme("colors.purple.700");
      }

      .secondary {
        stroke: theme("colors.purple.700");
      }
    }
  }

  &.text {
    &:focus {
      .primary {
        stroke: theme("colors.purple.600");
      }

      .secondary {
        stroke: theme("colors.purple.600");
      }
    }
  }
}

.secondary-button-admin {
  &.tonal,
  &.outlined,
  &.text {
    &:hover:not(:disabled) {
      .primary {
        stroke: theme("colors.slate.900");
      }

      .secondary {
        stroke: theme("colors.slate.900");
      }
    }

    &:focus {
      .primary {
        stroke: theme("colors.slate.800");
      }

      .secondary {
        stroke: theme("colors.slate.800");
      }
    }
  }
}

.tertiary-button-admin {
  &:hover:not(:disabled) {
    .primary {
      stroke: theme("colors.blue.700");
    }

    .secondary {
      stroke: theme("colors.blue.700");
    }
  }

  &:focus {
    .primary {
      stroke: theme("colors.blue.600");
    }

    .secondary {
      stroke: theme("colors.blue.600");
    }
  }
}

.danger-button-admin {
  &.tonal,
  &.outlined {
    &:hover:not(:disabled) {
      .primary {
        stroke: theme("colors.red.600");
      }

      .secondary {
        stroke: theme("colors.red.600");
      }
    }
  }

  &.text {
    &:hover:not(:disabled) {
      .primary {
        stroke: theme("colors.red.700");
      }

      .secondary {
        stroke: theme("colors.red.700");
      }
    }
  }

  &.tonal,
  &.outlined,
  &.text {
    &:focus {
      .primary {
        stroke: theme("colors.red.600");
      }

      .secondary {
        stroke: theme("colors.red.600");
      }
    }
  }
}

.link-button-icon {
  .primary {
    stroke: theme("colors.gray.700");
  }

  &:disabled {
    .primary {
      stroke: theme("colors.gray.500");
    }

    &:hover {
      .primary {
        stroke: theme("colors.gray.500");
      }
    }
  }

  &:hover {
    .primary {
      stroke: theme("colors.gray.900");
    }
  }
}

.link-button-decoration {
  &:hover {
    @apply underline;
  }

  &:disabled:hover {
    @apply no-underline;
  }
}

.gradient-button {
  background: linear-gradient(150.36deg,
    var(--primary-gradient-left) 0%,
    var(--primary-gradient-right) 100%);

  &:hover {
    background: linear-gradient(150.36deg,
      var(--primary-gradient-right) 0%,
      var(--primary-gradient-left) 100%);
  }

  &:disabled {
    background: var(--primary-200);
  }
}
