.pin {
  width: 57px;
  height: 65px;

  &.-learner {
    top: -29px;
  }
  &.-average {
    top: 24px;
  }
  &.-top {
    top: 24px;
  }

  &.-average,
  &.-top {
    svg {
      transform: rotate(180deg);
    }

    > .percentage {
      top: calc(50% + 3px);
    }
  }

  > span {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
  }

  > .percentage {
    position: absolute;
    top: calc(50% - 5px);
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
