.left-triangle {
  width: 0;
  height: 0;
  border-top: 12px solid var(--gray-200);
  border-left: 12px solid white;
}

.right-triangle {
  width: 0;
  height: 0;
  border-top: 12px solid var(--gray-200);
  border-right: 12px solid white;
}

.message__loading {
  width: 75%;
  padding: 0.5em 0.05em 0.25em;
  @apply transition duration-200 ease-in-out transform;

  .message__loading__text {
    display: inline-block;
    font-style: oblique;
  }

  .message__loading__spinner {
    display: inline-block;
    margin: 0 0 -2px 4px;
  }
}

.ai-assistant-new-message {
  trix-toolbar {
    display: none;
  }

  .form-textarea {
    cursor: text;
  }
}

.__mentionable-item {
  &.-active {
    @apply bg-gray-200 font-bold;
  }
}

[contentEditable="true"]:empty:not(:focus)::before {
  content: attr(data-placeholder);
  font-style: italic;
  @apply text-gray-600;
}

.mention {
  @apply font-bold;
}

.input-form.error {
  & .focus-within\:border-primary-700 {
    @apply border border-red-500;
  }

  & .focus-within\:shadow-brand {
    box-shadow: none;
  }
}

[id^="js-message-replies"] {
  & > [id^="js-reply"]:first-child {
    @apply mt-6 sm:mt-7;
  }

  & > [id^="js-reply"] {
    @apply mb-4;
  }

  & > [id^="js-reply"]:last-child {
    @apply mb-0;
  }
}

[id^="js-message-child-replies"] {
  & > [id^="js-reply"] {
    @apply mb-3;
  }

  & > [id^="js-reply"]:last-child {
    @apply mb-0;
  }
}
