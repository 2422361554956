.nudl-position-unset {
  & div {
    position: unset;

    &.hidden {
      display: flex;
    }
  }
}

.nudl-highlight {
  @apply overflow-x-auto;
}

.nudl-inline-display {
  & .relative {
    display: block;
  }
}
