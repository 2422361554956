.trix-content-pages .trix-content {
  > * {
    margin-bottom: 0.75rem;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  h1, h2, h3 {
    font-weight: 500;
    margin-top: 0.5rem;
    letter-spacing: 0em;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  h1 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-bottom: 1.75rem;
  }

  h2 {
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin-bottom: 1.25rem;
  }

  h3 {
    font-size: 1rem;
    line-height: 1.25rem;
    margin-bottom: 0.5rem;
  }

  p {
    font-weight: 400;
    font-size: 0.875rem;
    letter-spacing: 0em;
    line-height: 1.375rem;
    margin-bottom: 0.5rem;
  }

  align-left, align-right, align-center {
    width: 100%;
    display: block;
    white-space: normal;

    blockquote {
      display: inline-block;
    }

    figure {
      display: contents;
    }
  }

  align-left {
    text-align: left;
  }

  align-right {
    text-align: right;
  }

  align-center {
    text-align: center;
  }

  div {
    line-break: auto;
    line-height: inherit;
    white-space: normal;
  }

  a {
    color: var(--sky-600);
  }

  strong {
    font-weight: 700;
  }

  hr {
    margin-top: 30px;
    margin-bottom: 30px;
    border: 1px solid var(--gray-400);
  }

  & img {
    image-orientation: from-image;
    display: inline-block;
    margin: 0 auto !important;
    max-width: 100% !important;
  }

  & blockquote {
    padding: 15px 20px 15px 35px;
    margin: 0;
    position: relative;
    text-align: justify;
    border-left: none !important;

    &::before {
      line-height: 1.5rem;
      content: "\201C";
      font-family: Georgia, serif;
      font-size: 40px;
      font-weight: bold;
      position: absolute;
      left: 10px;
    }
  }

  ol, ul {
    padding-left: 1.5rem;

    > li {
      list-style-type: none;
    }
  }

  ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;

    &::before {
      content: counter(list-0, decimal) '. ';
    }
  }

  ul > li::before {
    content: '\2022';
  }

  li {
    margin-left: inherit;

    &::before {
      display: inline-block;
      white-space: nowrap;
      width: 1.2rem;
    }
  }

  li::before {
    margin-left: -1.5rem;
    margin-right: 0.3rem;
    text-align: right;
  }

  ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;

    &::before {
      content: counter(list-0, decimal) '. ';
    }

    & ol li {
      counter-increment: list-1;
      counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;

      &::before {
        content: counter(list-1, lower-alpha) '. ';
      }

      & ol li {
        counter-increment: list-2;
        counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;

        &::before {
          content: counter(list-2, lower-roman) '. ';
        }

        & ol li {
          counter-increment: list-3;
          counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;

          &::before {
            content: counter(list-3, decimal) '. ';
          }

          & ol li {
            counter-increment: list-4;
            counter-reset: list-5 list-6 list-7 list-8 list-9;

            &::before {
              content: counter(list-4, lower-alpha) '. ';
            }

            & ol li {
              counter-increment: list-5;
              counter-reset: list-6 list-7 list-8 list-9;

              &::before {
               content: counter(list-5, lower-roman) '. ';
              }

              & ol li {
                counter-increment: list-6;
                counter-reset: list-7 list-8 list-9;

                &::before {
                  content: counter(list-6, decimal) '. ';
                }

                & ol li {
                  counter-increment: list-7;
                  counter-reset: list-8 list-9;

                  &:before {
                    content: counter(list-7, lower-alpha) '. ';
                  }

                  & ol li {
                    counter-increment: list-8;
                    counter-reset: list-9;

                    &::before{
                      content: counter(list-8, lower-roman) '. ';
                    }

                    & ol li {
                      counter-increment: list-9;

                      &::before {
                        content: counter(list-9, decimal) '. ';
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .attachment {
    width: 100%;

    &.attachment--preview {
      text-align: inherit;
    }
  }

  .attachment__caption {
    display: none !important;
  }

  action-text-attachment {
    .attachment {
      padding: 0 !important;
      max-width: 100% !important;
    }

    &[content-type~='vnd.rubyonrails.horizontal-rule.html'] .attachment--content {
      padding: 1.5em 0 0.5em !important;
      margin: 0.5rem 0;

      hr {
        margin: 0;
        width: 100%;
        border-color: var(--gray-600);
      }
    }
  }

  div:not(.attachment-gallery) {
    .attachment--preview {
      margin: 1px 2px 4px;
      vertical-align: bottom;
      width: auto;
    }

    .attachment--content {
      position: relative;
      display: inline-block;
      padding-bottom: 56.25%; /*16:9*/
      padding-top: 0;
      height: 0;
      overflow: hidden;
      width: 100%;

      &.attachment--jpg,
      &.attachment--png,
      &.attachment--heic {
        height: 100% !important;
      }
    }
  }

  .attachment-gallery {
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.75rem;
    position: relative;

    .attachment {
      flex: 1 0 33%;
      padding: 0 0.5em;
      max-width: 33%;
    }

    .attachment--preview {
      height: 100%;

      img {
        display: block !important;
      }
    }

    .attachment,
    > action-text-attachment {
      flex: 1 0 33%;
      padding: 0 0.5em;
      max-width: 33%;
    }

    &.attachment-gallery--2,
    &.attachment-gallery--4 {
      > action-text-attachment,
      > .attachment {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }
}
