/* Styles for drag and drop on rich text editor fields */

.drag-over {
  @apply relative border-0;

  .drag-message {
    @apply block;
  }

  trix-editor {
    background-color: var(--primary-100);
    border: 2px dashed var(--primary-base) !important;
    border-top: 0px !important;
  }
}
