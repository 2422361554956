.admin-number-picker {
  @apply bg-white border-slate-500 focus-within:bg-slate-25 focus-within:hover:bg-slate-25;

  &:hover {
    @apply bg-slate-50;

    & input[type="text"] {
      @apply bg-slate-50;
    }
  }

  &:focus-within {
    & input[type="text"] {
      @apply bg-slate-25;
    }
  }

  & input[type="text"]:focus {
    outline: none !important;
    box-shadow: none;
  }

  & .increase-icon,
  & .decrease-icon {
    @apply cursor-pointer;

    & .primary {
      stroke: theme("colors.slate.700");
    }
  }

  &.disabled {
    @apply bg-slate-50 border-slate-400 hover:bg-slate-50 cursor-not-allowed;

    & .increase-icon,
    & .decrease-icon {
      @apply cursor-not-allowed;

      & .primary {
        stroke: theme("colors.slate.500");
      }
    }
  }
}
