.input-field:focus,
.text-area-field:focus {
  outline: none !important;
  border-color: var(--blue-500);
  box-shadow: none;
}

.error .input-field:focus,
.error .text-area-field:focus {
  border-color: var(--red-600);
}

.warning .input-field:focus,
.warning .text-area-field:focus {
  border-color: var(--yellow-500);
}

.success .input-field:focus,
.success .text-area-field:focus {
  border-color: var(--green-500);
}

.brand .input-field:focus {
  border-color: var(--primary-700);
}

.invisible-input {
  opacity: 0;
  position: absolute;
  height: 0.1px;
  width: 0.1px;
}
