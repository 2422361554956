.fixed-header {
  @apply py-2 sm:py-4;
  @apply bg-white;
  @apply border-primary-400 border-t-2;

  &.-animated {
    animation-duration: 0.2s;
    animation-name: fixedHeader;
    animation-fill-mode: forwards;
  }
}

@keyframes fixedHeader {
  0% {
    @apply bg-gray-400;
    @apply border-gray-100;
  }

  100% {
    @apply bg-white;
    @apply border-primary-400;
  }
}

.sticky-section {
  @apply z-20;
  @apply py-1 sm:py-2;
  @apply bg-gray-400;
  @apply border-gray-100;

  -webkit-box-shadow: 0px 15px 15px -5px rgba(0,0,0,0.3);
  box-shadow: 0px 15px 15px -5px rgba(0,0,0,0.3);

  &.-animated {
    animation-duration: 1s;
    animation-name: stickyHeader;
    animation-fill-mode: forwards;
  }

  &.-top {
    @apply border-t-2;

    -webkit-box-shadow: 0px 15px 15px -5px rgba(0,0,0,0.2);
    box-shadow: 0px 15px 15px -5px rgba(0,0,0,0.2);
  }

  &.-bottom {
    @apply border-b-2;

    -webkit-box-shadow: 0px -15px 15px -5px rgba(0,0,0,0.2);
    box-shadow: 0px -15px 15px -5px rgba(0,0,0,0.2);
  }

  h1 {
    @apply text-lg font-bold truncate;
  }

  .pill {
    &.bg-gray-200 {
      @apply bg-gray-800 text-white;
    }
  }

  .header-container {
    width: calc(100% - 34px);
  }
}

@keyframes stickyHeader {
  0% {
    @apply bg-white;
    @apply border-white;
  }

  100% {
    @apply bg-gray-400;
    @apply border-gray-100;
  }
}

.sticky-counterweight {
  height: 0px;

  &.-active {
    height: 12px;

    @media (min-width: 640px) {
      height: 20px;
    }
  }
}
