@supports ((position: -webkit-sticky) or (position: sticky)) {
  .h-vat-sidebar {
    height: calc(100vh - 12rem);
  }

  @media (min-width: 641px) {
    .h-vat-sidebar {
      &.preview-mode {
        height: calc(100vh - 16rem);
      }

      &.with-filter {
       height: calc(100vh - 14rem);

        &.preview-mode {
          height: calc(100vh - 18rem);
        }
      }
    }
  }

  @media (max-width: 640px) {
    .h-vat-sidebar {
      &.preview-mode {
        height: calc(100vh - 19rem);
      }

      &.with-filter {
        height: calc(100vh - 17rem);

        &.preview-mode {
          height: calc(100vh - 21rem);
        }
      }
    }
  }

  @screen lg {
    /* When there is only the header */
    .top-sidebar {
      top: 4rem;
    }
    .h-sidebar {
      height: calc(100vh - 4rem);
    }

    .sidebar-clear-preview-header {
      .top-sidebar {
        top: 7rem;
      }
      .h-sidebar {
        height: calc(100vh - 7rem);
      }
    }
  }
}

.sidebar
.aside {
  @apply max-w-full shrink-0;

  min-width: 350px;
}

@screen lg {
  .sidebar,
  .aside {
    @apply max-w-full shrink-0;

    width: 350px;
  }
}

.toolbar {
  .toolbar-item {
    @apply border-r border-gray-300;
  }

  .toolbar-item:last-child {
    @apply border-0;
  }
}

@media (max-width: 1279px) {
  .toolbar {
    @apply block;

    .toolbar-active-item {
      @apply text-primary-700;

      .primary {
        stroke: theme("colors.primary.700");
      }

      & #learning-journey-icon,
      & .icon-click-target,
      & .icon-clipboard-check {
        .primary {
          stroke: none;
          fill: theme("colors.primary.500");
        }

        .secondary {
          stroke: none;
          fill: theme("colors.primary.700");
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  #js-sidebar.toolbar-active {
    @apply block;
  }

  #js-aside.toolbar-active {
    @apply block;
  }

  #js-main.toolbar-active {
    @apply block;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  #js-sidebar.toolbar-active {
    @apply block;
  }

  #js-aside.toolbar-active {
    @apply block absolute inset-0;
  }

  #js-main.toolbar-active {
    @apply block;
  }
}
