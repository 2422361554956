@media (max-width: 640px) {
  .programs-header {
    background-image: none !important;
  }
}

@media (min-width: 640px) {
  .background-tint {
    background-color: rgba(var(--primary-base-rbg), 0.7);
  }
}
