input[type="date"] {
  &:focus {
    box-shadow: none;
    border: none;
  }
}

.admin-date-picker {
  @apply border-slate-400 bg-white text-slate-700;

  &.focused {
    @apply border-purple-600;
  }

  .primary {
    stroke: theme("colors.slate.700");
  }

  &.disabled {
    @apply border-slate-200 bg-slate-50 text-slate-500;

    .primary {
      stroke: theme("colors.slate.500");
    }
  }
}
