.program-image {
  height: 200px;
  width: 200px;
  background-position: center;
  background-repeat: no-repeat;
}

.program-image-small {
  height: 165px;
  width: 165px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
