@use "tributejs/tribute";

.tribute-container {
  max-height: 220px !important;
}

.tribute-container li {
  padding-left: 12px;
  padding-right: 12px;
}
