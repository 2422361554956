.inputs-tab {
  letter-spacing: 0.45px;

  @apply border-b-2 border-gray-400;

  &.active,
  &:not(.disabled):hover {
    letter-spacing: 0;

    @apply border-b-2 border-primary-700;

    .header {
      @apply text-primary-700 font-bold;
    }

    .pill {
      @apply bg-primary-700 text-white;
    }
  }

  &.disabled {
    @apply cursor-not-allowed;
  }
}
