/*
  Internet Explorer 11 doesn't support nested calls to the calc function and it also doesn't support CSS variables.
  Tailwind classes for space between child elements uses both the features above as can be seen here: https://tailwindcss.com/docs/space#class-reference
  In order to keep the same approach on the views, we decided to recreate the same funcionality using the same classes naming pattern with a prefix "nu-".
  When adding new options, use the same value (rem) from the Tailwind documentation.
*/

.promote-space-y-0 > * + * {
  margin-top: 0;
  margin-bottom: 0;
}

.promote-space-y-1 > * + * {
  margin-top: 0.25rem;
  margin-bottom: 0;
}

.promote-space-y-2 > * + * {
  margin-top: 0.5rem;
  margin-bottom: 0;
}

.promote-space-y-3 > * + * {
  margin-top: 0.75rem;
  margin-bottom: 0;
}
.promote-space-y-3-reverse > * + * {
  margin-top: 0;
  margin-bottom: 0.75rem;
}

.promote-space-y-4 > * + * {
  margin-top: 1rem;
  margin-bottom: 0;
}

.promote-space-y-5 > * + * {
  margin-top: 1.25rem;
  margin-bottom: 0;
}

.promote-space-y-6 > * + * {
  margin-top: 1.5rem;
  margin-bottom: 0;
}

.promote-space-x-1 > * + * {
  margin-right: 0;
  margin-left: 0.25rem;
}

.promote-space-x-2 > * + * {
  margin-right: 0;
  margin-left: 0.5rem;
}

.promote-space-x-3 > * + * {
  margin-right: 0;
  margin-left: 0.75rem;
}

.promote-space-x-4 > * + * {
  margin-right: 0;
  margin-left: 1rem;
}
