@use "trix/dist/trix";

/**
We need to override trix.css’s image gallery styles to accommodate the
<action-text-attachment> element we wrap around attachments. Otherwise,
images in galleries will be squished by the max-width: 33%; rule.
**/
.trix-content {
  .attachment-gallery {
    > action-text-attachment,
    > .attachment {
      flex: 1 0 33%;
      max-width: 33%;
      padding: 0 0.5em;
    }

    &.attachment-gallery--2,
    &.attachment-gallery--4 {
      > action-text-attachment,
      > .attachment {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }

  div:not(.attachment-gallery) action-text-attachment .attachment {
    max-width: 100% !important;
    padding: 0 !important;

    &.attachment--preview > img ~ .attachment__caption .rich-attachment-link {
      display: none;
    }
  }

  .rich-text-mention {
    color: #1d4ed8;
    background-color: #dbeafe;
    border-radius: 0.25rem;
  }

  pre {
    background: #f4f4f4;
    border: 1px solid #ddd;
    border-left: 3px solid #A227A5;
    color: #666;
    page-break-inside: avoid;
    font-family: monospace;
    font-size: 0.9;
    line-height: 1.4;
    margin-bottom: 1.6em;
    max-width: 100%;
    padding: 1em 1.5em;
    display: block;
    word-wrap: break-word;
  }
}

trix-toolbar .trix-button-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: visible;
}

trix-toolbar .trix-button-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 0;
  border-bottom-color: transparent;
  border-radius: 3px;
  border-top-color: transparent;
  margin-bottom: 0;
}

trix-toolbar .trix-button-group:not(:first-child) {
  margin-left: 0;
}

trix-toolbar .trix-button {
  border-bottom: 0;
}

trix-toolbar .trix-button--icon {
  height: 2rem;
  width: 2.2rem;
}

trix-toolbar .trix-button--icon::before {
  background-size: 1.2rem;
}

trix-toolbar .trix-button:not(:first-child) {
  border-left: 0;
}

trix-toolbar {
  .trix-button--icon-strike,
  .trix-button--icon-quote {
    display: none;
  }
}

trix-editor:focus {
  box-shadow: none;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

trix-editor primary-color {
  @apply text-primary-700;
}

/* Mention icon */
.trix-button--icon-mention::before { background-image: url("../../assets/images/default/at-symbol.svg"); }

/* AI icon */
.trix-button--icon-ai::before { background-image: url("../../assets/images/default/ai.svg"); }

/* Emoji icon */
.trix-button--icon-emoji::before { background-image: url("../../assets/images/default/emoji-happy.svg"); }

.attachment-icon-component {

  width: 30px;
  height: 40px;

  margin-right: 10px;
  background-image: url('../../assets/images/ng_admin/svg/attachments/file.svg');
  background-repeat: no-repeat;

  &.-doc {
    background-image: url('../../assets/images/ng_admin/svg/attachments/doc.svg');
  }
  &.-img {
    background-image: url('../../assets/images/ng_admin/svg/attachments/img.svg');
  }
  &.-url {
    background-image: url('../../assets/images/ng_admin/svg/attachments/url.svg');
  }
  &.-pdf {
    background-image: url('../../assets/images/ng_admin/svg/attachments/pdf.svg');
  }
  &.-video {
    background-image: url('../../assets/images/ng_admin/svg/attachments/video.svg');
  }
  &.-scorm {
    background-image: url('../../assets/images/ng_admin/svg/attachments/scorm.svg');
  }
}

.attachment {
  border: none !important;
}

.rich-attachment-link-before:before {
  content: ' · ';
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: var(--gray-400);
  display: inline-block;
  padding-right: 0.2rem;
}

.rich-attachment-link {
  text-decoration: underline !important;
}

.attachment-icon-component {
  display: inline-block;
  float: left;
  margin: 0.1rem;
}

.file-caption--true {
  vertical-align: top;
  float: left;
  margin: 0.2rem;
  padding-left: 0.1rem;
}

.attachment__name {
  font-weight: bold !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 8rem;
  text-align: left;
}

/* ActionText embeds */
.link_to_embed {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 0.85rem;
  padding-top: 1rem;

  .embed-text {
    margin-right: 0.5rem;
  }

  .embed-button {
    color: #fff;
    font-weight: 600;
    border-radius: .5rem;
    background-color: #00a55e;
    cursor: pointer;
    padding-left: 0.3rem!important;
    padding-right: 0.3rem!important;
    padding-top: .15rem!important;
    padding-bottom: .15rem!important;
  }
}

.trix-content {
  img {
    display: inline-block;
    max-width: 100%;
    height: auto;
  }

  .embed {
    display: inline-block;
    line-height: 1;
    margin: 1em 0 !important;
    padding: 0 !important;
    width: 100%;
  }

  iframe {
    display: inline-block !important;
  }

  .embed {
    display: inline-block;
    margin: 1em 0 !important;
    padding: 0 !important;
    width: 100%;
  }

  /* A couple handy classes for responsive YouTube and other iframe embeds */
  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }

  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/* Trix attachment formatting */
div:not(.attachment-gallery) > .attachment--preview {
  margin: 0.6em 0;
  text-align: center;
  width: 100%;

  img[data-trix-mutable] ~ .attachment__caption {
    display: none !important;
  }

  &.attachment--jpg,
  &.attachment--png,
  &.attachment--heic {
    img ~ .attachment__caption {
      .rich-attachment-link {
        display: none !important;
      }
      .attachment__size {
        display: none !important;
      }
    }
  }
}

action-text-attachment[content-type^="image/"] figure {
  width: fit-content !important;
}

.attachment__toolbar .attachment__metadata-container {
  display: none !important;
}

.attachment--content {
  &[data-trix-attachment*="embed-container"] {
    width: 100%;
  }
}
