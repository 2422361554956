/*
 Hi there, if you're interested in styling icons in NU here is some guidance:

 If you're wanting just one color for the icon paths you can do the following:
 `text-<color> fill-current` e.g. `text-red-400 fill-current`

 This will set the fill for all svg paths to use the text color.


 If you're wanting to set different primary and secondary colors, do the following:
 `primary-fill-<color> secondary-fill-<color>` e.g. `primary-fill-red-600 secondary-fill-white`

 This is a lot more Tailwind-y and flexible than defining manual styles like in this file.
*/

.hover\:icon-brand:hover,
.icon-brand {
  .primary {
    stroke: theme("colors.primary.500");
  }
}

.hover\:icon-brand-dark:hover {
  .primary {
    stroke: theme("colors.primary.700");
  }
}

.hover\:icon-brand-dark-stroke:hover {
  .primary {
    fill: theme("colors.primary.500");
  }

  .secondary {
    fill: theme("colors.primary.700");
  }
}

.icon-admin-sidebar {
  .primary,
  .secondary {
    stroke: theme("colors.white");
  }
}

.icon-admin-sidebar:hover,
.icon-admin-sidebar-active {
  .primary,
  .secondary {
    stroke: theme("colors.purple.600");
  }
}

.calendar-icon-text {
  font-size: 8px;
}

.hover\:primary-stroke-purple-600:hover {
  & .primary {
    stroke: theme("colors.purple.600");
  }

  & .secondary {
    stroke: theme("colors.purple.600");
  }
}

.info-icon-tooltip {
  & > div,
  & > div > div {
    display: inline;
  }
}
