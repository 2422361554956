.admin-dropdown-container {
  @apply flex flex-col rounded-md border border-slate-400 bg-white text-slate-800 cursor-pointer min-w-48;

  &:hover {
    @apply border-purple-300;
  }

  .admin-dropdown-container-icon {
    & .primary {
      stroke: theme("colors.slate.700");
    }
  }

  &.disabled {
    @apply bg-slate-25 border-slate-300 text-slate-400 cursor-not-allowed;

    .admin-dropdown-container-icon {
      & .primary {
        stroke: theme("colors.slate.400");
      }
    }
  }
}

.admin-dropdown-item {
  @apply flex items-center cursor-pointer rounded-md border border-transparent px-1 py-2 text-slate-800;

  &:hover {
    @apply text-purple-600 bg-purple-50 primary-stroke-purple-600;

  }

  &.disabled {
    @apply text-slate-400;

    &:hover {
      @apply cursor-not-allowed;
    }
  }

  &.hidden {
    display: none;
  }
}
