.tab-primary {
  letter-spacing: 0.45px;

  @apply border-b-2 border-gray-400;

  &.active,
  &:not(.disabled):hover {
    letter-spacing: 0;

    &.brand {
      @apply border-b-2 border-primary-700;

      .header {
        @apply text-primary-700 font-bold;
      }

      .pill {
        @apply bg-primary-700 text-white;
      }
    }

    &.green {
      @apply border-b-2 border-green-500;

      .header {
        @apply text-green-700 font-bold;
      }

      .pill {
        @apply bg-green-500 text-green-900;
      }
    }

    &.gray {
      @apply border-b-2 border-gray-900;

      .header {
        @apply text-gray-900 font-bold;
      }

      .pill {
        @apply bg-gray-900 text-white;
      }
    }

    &.red {
      @apply border-b-2 border-red-500;

      .header {
        @apply text-red-500 font-bold;
      }

      .pill {
        @apply bg-red-500 text-white;
      }
    }

    &.yellow {
      @apply border-b-2 border-yellow-500;

      .header {
        @apply text-yellow-600 font-bold;
      }

      .pill {
        @apply bg-yellow-500 text-yellow-900;
      }
    }
  }

  &.disabled {
    @apply cursor-not-allowed;
  }
}

.tab-secondary {
  @apply bg-gray-200 text-lg font-bold min-w-0 sm:min-w-160 h-12 flex items-center justify-center mr-1 cursor-pointer px-2 sm:px-4 flex-shrink-0;

  &:not(.locked) {
    &.active,
    &:hover {
      @apply bg-white border-r border-l border-t-2 shadow-tab text-primary-700;

      border-right-color: var(--gray-200);
      border-left-color: var(--gray-200);
      border-top-color: var(--primary-500);
    }
  }
}

.tab-admin {
  letter-spacing: 0.45px;

  @apply text-slate-700;

  &.active {
    letter-spacing: 0;
    @apply border-b-2 border-purple-600;

    .header {
      @apply text-purple-600;
    }
  }

  &:not(.disabled):not(.active):hover {
    @apply bg-purple-100 text-purple-700;
  }

  &.disabled {
    @apply text-slate-500 cursor-not-allowed;

    & .badge {
      @apply bg-slate-100 text-slate-500;
    }
  }

  &.purple {
    &.active {
      & .badge {
        @apply bg-purple-600 text-white;
      }
    }

    &:not(.disabled):not(.active):hover {
      & .badge {
        @apply bg-purple-200 text-purple-700;
      }
    }
  }
}

.tab-pill {
  @apply w-full text-center cursor-pointer;

  &.active {
    @apply bg-white font-semibold shadow-center-sm;
  }
}

.goals-tabs {
  & ul {
    @apply mx-0;
  }
}
