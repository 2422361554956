.vat-line {
  &::after {
    bottom: -45px;
    content: "";
    height: 66px;
    left: 25.5px;
    position: absolute;
    width: 5px;
    z-index: 1;
  }

  &.gray {
    &::after {
      @apply bg-gray-400;
    }
  }

  &.yellow {
    &::after {
      @apply bg-yellow-500;
    }
  }

  &.green {
    &::after {
      @apply bg-green-500;
    }
  }
}

.js-content-page-vat-active {

  .vat-title {
    @apply font-bold;
  }
}

.program-module-border {
  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 45px;
    left: 25.5px;
    display: block;
    width: 5px;
    height: calc(100% - 45px);
  }

  &.border-gray-400 {
    &::after {
      @apply bg-gray-400;
    }
  }

  &.border-yellow-500 {
    &::after {
      @apply bg-yellow-500;
    }
  }

  &.border-green-500 {
    &::after {
      @apply bg-green-500;
    }
  }
}

.vat-line:last-child,
.vat-line.hide-line {
  &::after {
    height: 0;
  }

  .program-module-border {
    position: relative;

    &::after {
      top: 0;
      height: 100%;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
}
