.accordion-icon {
  transition: transform 0.5s;

  &.collapsed {
    transform: rotate(180deg);
  }
}

.accordion-content {
  overflow: hidden;
  transition: height 0.5s ease-in-out;

  &.collapsed {
    height: 0;
    overflow: hidden;
    transition: height 0.5s cubic-bezier(0, 1, 0, 1);
  }
}
