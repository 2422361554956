.header-item,
.menu-item {
  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
}

.header-icon {
  & .primary,
  & .secondary {
    stroke: rgba(255, 255, 255, 0.6);
  }

  & .icon-click-target,
  & .icon-user-group,
  & .icon-clipboard-check {
    & .primary {
      stroke: none;
      fill: rgba(255, 255, 255, 0.6);
    }

    & .secondary {
      stroke: none;
      fill: rgba(255, 255, 255, 0.7);
    }
  }
}

.header-item-active,
.hover\:header-item-active:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.hamburger-menu {
  border-radius: 50%;

  span {
    height: 2px;
    margin-bottom: 5px;
    margin-top: 5px;
    transition: 0.33s;
    width: 24px;
  }
}

.menu {
  @apply hidden;

  @screen lg {
    @apply flex;
  }
}

.display-menu {
  overflow-y: hidden;

  .hamburger-menu {
    span:first-child {
      width: 12px;
      transform: rotate(45deg) translate(2px, 1px);
    }

    span:nth-child(2) {
      transform: rotate(-45deg);
    }

    span:nth-child(3) {
      width: 12px;
      transform: rotate(45deg) translate(6px, -9px);
    }
  }

  .menu {
    @apply block h-(screen-16) w-full overflow-y-auto;
  }

  .items {
    @apply block;
  }
}

.unread-notification {
  border-radius: 50%;
  height: 9px;
  position: absolute;
  right: 8px;
  top: 2px;
  width: 9px;

  z-index: 2;
}

@screen lg {
  .unread-notification {
    position: absolute;
    height: 12px;
    width: 12px;
    right: -2px;
    top: 0;
  }
}
