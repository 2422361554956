.tab-block {
  @apply border border-gray-300;

  &.active,
  &:hover {
    @apply border border-primary-500 bg-primary-100;

    &.brand {
      .headers {
        @apply text-primary-800;
      }
    }

    &.green {
      @apply border border-green-600 bg-green-100;

      .headers {
        @apply text-green-900;
      }
    }

    &.gray {
      @apply border border-gray-600 bg-gray-100;

      .headers {
        @apply text-gray-900;
      }
    }

    &.red {
      @apply border border-red-500 bg-red-100;

      .headers {
        @apply text-red-800;
      }
    }

    &.yellow {
      @apply border border-yellow-500 bg-yellow-100;

      .headers {
        @apply text-yellow-800;
      }
    }
  }
}
