/**
.form-textarea was a style in tailwindcss/custom-forms which has now been replaced by tailwindcss/forms
This file adds the styling until @tailwindcss/forms release a new styling class for textareas
**/

.form-textarea {
  border: 1px solid var(--gray-400);
  appearance: none;
  padding: .5rem .75rem;
  font-size: 1rem;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;

  &:focus {
    outline: none;
  }
}

#js-feed-form {
  & .form-textarea {
    border: 0;
  }

  & .form-textarea.trix-content {
    border: 0;
    border-top: 1px solid var(-gray-400);
    box-shadow: none;
  }
}

:focus.form-textarea.trix-content, .form-textarea.trix-content {
  border: 0;
  border-top: 1px solid var(-gray-400);
  box-shadow: none;
}

.form-textarea.textarea-edit {
  border: unset;
  padding: unset;
  font-size: unset;
}
